import { isBrowserSupported as isBrowsersSupportedByWebAR } from "@banuba/webar"
import Widget from "@lib/widget"

/** Widget with app wrapped in a modal window */
export default Widget({
  name: "tint-vto",
  /**
   * To isolate the app scripts, it must run on a separate Realm (see @lib/widget for details on the Realm).
   * To run the app on the separate Realm, it must be loaded from an external script file.
   * The path to the script file differs between Vite’s `dev` and `build modes, so the environment variable is presented.
   * The actual variable value is set by vite.config.ts.
   */
  src: new URL(import.meta.env.APP_SRC, import.meta["url"]), // the ["url"] syntax is used to trick Vite to note transpile the import.meta.url
  exports: [
    "open",
    "close",
    "useWebcam",
    "usePhoto",
    "applyProduct",
    "applyProductByCustomerSku",
    "isCustomerSkuExist",
    "lang",
    "shopifyApplyProduct",
  ],
  events: [
    "open",
    "close",

    "addToCart",
    "removeFromCart",
    "redirectToCart",

    "applyProduct",
    "removeAppliedProduct",
    "replaceAppliedProduct",

    "demo",

    "analysisFinished",
  ],
})

export const isBrowserSupported = () =>
  typeof window !== "undefined" && import.meta && isBrowsersSupportedByWebAR()
